.read-pas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
}

.form-container {
    margin-top: 10%;
}

.form-pas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.upload-box {
    font-size: 16px;
    background: white;
    border-radius: 50px;
    box-shadow: 5px 5px 10px black;
    width: 350px;
    outline: none;
}

::-webkit-file-upload-button, input[type="file"]::file-selector-button {
    color: white;
    background: #031F30;
    padding: 20px;
    border: none;
    border-radius: 50px;
    box-shadow: 1px 0 1px 1px #4b3c44;
    outline: none;
}

::-webkit-file-upload-button:hover, input[type="file"]::file-selector-button:hover {
    /* background: #438a5e;
	box-shadow: 1px 0 1px 1px #1f5f37; */
	cursor: pointer;
}

.form-pas button{
    margin-left: 10px;
    margin-top: 10px;
}

.download-container {
    margin-top: 5%;
    padding: 10px;
    border: 2px solid #000;
    border-radius: 29px;
}

.download-container-text p {
    text-align: center;
}

.btn-descargar {
    background-color: #E73244;
    border: 1px solid #aa1c2a;
    color: white;
}
.btn-descargar:hover {
    background-color: white;
    color: #E73244;
    border-color: #E73244;
}

.btn-descargar-container {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

