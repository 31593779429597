.pas-content {
    position: relative;
    padding: 10px 30px;
    width: 100%;
}

.table-pas {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    overflow-x: auto;
    height: 305px;
    /* font-size: 12px; */
}

.table-pas table tr th {
    text-align: center;
}

.table-pas th, td {
    text-align: left;
    padding: 8px;
}

.table-pas tr:nth-child(even) {
    background-color: #f2f2f2;
}

.pas-header {
    text-align: justify;
    margin-bottom: 20px;
}
.pas-header h3 {
    font-weight: 400;
}

.pas-header h3 span {
    font-weight: bold;
}