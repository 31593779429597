/* ALERTAS */
.alert-content {
  margin-top: 2%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.alert-content p{
  margin-bottom: 0;
  text-align: center;
}

.alert-box {
  border-radius: 8px;
  width: 80%;
  padding: 5px;
}

.success-alert{
  background-color: #0B8C0C;
  border: 2px solid #087609;
}

.success-alert p{
  color: white;
}

.error-alert {
  background-color: #B21818;
  border: 2px solid #870f0f;
}

.error-alert p{
  color: white;
}

.neutral-alert {
  border: 2px solid black;
}

.neutral-alert p {
  color: black;
}