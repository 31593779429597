@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center {
  text-align: center;
}


/* BOTONES */

.btn {
  border-radius: 29px;
  font-size: 15px;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.btn:hover {
  transition: 0.3s;
}

.btn-regresar{
  background-color: #031F30;
  border: 1px solid #081F2D;
  color: white;
}    
.btn-regresar:hover{ 
  background-color: white;
  color: #081F2D;
  border-color: #081F2D;
}

.btn-enviar {
  background-color: #3f9e22;
  border: 1px solid #327a1c;
  color: white;
}
.btn-enviar:hover {
  background-color: white;
  color: #3f9e22;
  border-color: #3f9e22;
}
.btn-agregar {
  background-color: #3f9e22;
  border: 1px solid #327a1c;
  color: white;
}
.btn-agregar:hover {
  background-color: white;
  color: #3f9e22;
  border-color: #3f9e22;
}
.btn-cargar {
  background-color: #E73244;
  border: 1px solid #aa1c2a;
  color: white;
}
.btn-cargar:hover {
  background-color: white;
  color: #E73244;
  border-color: #E73244;
}

/* GENERAL TABLE */
.sticky-header {
  position: sticky;
  top: 0;
  background: #fff;
  font-size: 14px;
}

.table-rows {
  font-size: 12px;
}
